<template>
  <div class="about">
    <!--Nav-->
    <topSection :isSliderOn="false" />
    <!--Map-->
    <section>
      <div class="gMap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.2108501650405!2d30.647394015293006!3d36.90922277992627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38e2725a6a625%3A0xcb1769c43899d629!2sN%C3%B6bet%C3%A7i%20Teknik%20Servis%207%2F%2024%20Kesintisiz%20Hizmet....*21!5e0!3m2!1str!2str!4v1618350253520!5m2!1str!2str"
          class="map"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
    <div class="underline"></div>
    <!--Contact us-->
    <section>
      <div class="contactUs">
        <contactUs />
      </div>
    </section>
    <!--Footer-->
    <section>
      <div class="footer">
        <footerr />
      </div>
    </section>
  </div>
</template>

<script>
import contactUs from '@/components/home/ContactUs.vue';
import footerr from '@/components/home/Footer.vue';
import topSection from '@/components/home/TopSection.vue';

export default {
  components: {
    contactUs,
    footerr,
    topSection,
  },
};
</script>

<style>
.gMap {
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 60%;
  height: 500px;
}
.underline {
  margin-right: auto !important;
  margin-left: auto !important;
  width: 70%;
  border-radius: 7.5px;
  height: 15px;
  margin-top: 10px;
  background-color: #ed1c24;
}
@media only screen and (max-width: 500px) {
  .gMap {
    padding-top: -150px;
  }
  .map {
    width: 100%;
    height: 500px;
  }
}
</style>
